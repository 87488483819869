<template>
  <b-container>
    <b-row>
      <b-col class="mb-1">
        <h2>{{ farmHveAudit.farm.name }}</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card
          v-if="farmHveAudit.passed"
          class="px-2 Card"
        >
          <b-card-title>Félicitations,</b-card-title>
          <b-card-text>
            {{ hveGamingText }}
          </b-card-text>
          <b-img
            src="@/assets/images/illustrations/badge.svg"
            alt="medal"
            class="Card__img"
          />
          <router-link :to="{name: 'farm-hve'}"><b-button variant="primary">
            Voir le détail
          </b-button></router-link>
        </b-card>
        <b-row>
          <b-col>
            <statistic-card-vertical
              icon="GlobeIcon"
              :statistic="totalFarms"
              statistic-title="Nombre total d'exploitations participant au projet"
              style="height: 90%;"
            />
          </b-col>
          <b-col>
            <statistic-card-vertical
              icon="UsersIcon"
              color="warning"
              :statistic="totalFarmers"
              statistic-title="Nombre d'exploitants de votre coopérative participant au projet"
              style="height: 90%;"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-card title="Bonjour, et bienvenue sur la plateforme Agriprogress !">
          Agriprogress est un projet porté par le living lab d'expérimentation et d'innovation agricole Fermes LEADER. Il s'intègre au sein de la nouvelle démarche 3ième voie de l'agriculture lancée par le groupe Bioline, branche agriculture du groupe coopératif InVivo. Cette démarche vise à reconnaître les efforts des exploitants et les engage sur des résultats. Au sein d'Agriprogress, plusieurs métiers collaborent, parmis eux, les consultants/experts en agronomie de chez Agrosolutions ou encore les développeurs de logiciels SMAG. Agriprogress a pour objectif de développer une plateforme unique permettant de connecter les acteurs de l'aval et de l'amont agricole et ainsi de valoriser chaque étape de la production. Pour ce faire, chaque maillon de la chaine est impliqué dans la construction de la plateforme et amené à s'exprimer sur les indicateurs qu'il souhaite voir apparaitre au sein de celle-ci.
          <br><br>
          Pour cette version pilote, nous avons décidé de construire la plateforme sur des indicateurs inspirés du référentiel Haute Valeur Environnemental, complété d'un indicateur d'émission de gaz à effet de Serre.
          <br><br>
          Pour vous rendre sur les tableaux de bord correspondants et visualiser vos résultats, veuillez cliquer sur les onglets HVE ou GES.
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer, BRow, BCol, BCard, BCardTitle, BCardText, BButton, BImg
} from "bootstrap-vue";
import { mapState } from "vuex";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BButton,
    BImg,
    StatisticCardVertical
  },
  data() {
    return {
      userData: getUserData()
    };
  },
  computed: {
    ...mapState({
      globalHveAudit: state => state.global.hveAudit,
      farmHveAudit: state => state.farm.hveAudit,
      coopHveAudit: state => state.cooperative.hveAudit
    }),
    totalFarmers() {
      return this.coopHveAudit ? this.coopHveAudit.failing_farms_count + this.coopHveAudit.passing_farms_count : 0;
    },
    totalFarms() {
      return this.globalHveAudit ? this.globalHveAudit.failing_farms_count + this.globalHveAudit.passing_farms_count : 0;
    },
    farmChartData() {
      return [{
        name: "Exploitations",
        data: [
          0,
          this.totalFarms
        ]
      }];
    },
    farmerChartData() {
      return [{
        name: "Exploitants",
        data: [
          0,
          this.totalFarmers
        ]
      }];
    },
    hveGamingText() {
      if (this.farmHveAudit.option_a?.passed && this.farmHveAudit.option_b?.passed) {
        return "Vous êtes certifiable HVE selon les options A et B.";
      }
      if (this.farmHveAudit.option_a?.passed) {
        return "Vous êtes certifiable HVE selon l'option A.";
      }
      if (this.farmHveAudit.option_b?.passed) {
        return "Vous êtes certifiable HVE selon l'option B.";
      }
      return "Vous n'êtes pas certifiable HVE.";
    }
  }
};
</script>

<style lang="scss" scoped>
  .Card {
    position: relative;

    &__img {
      position: absolute;
      top: 0;
      right: 2rem;
    }
  }
</style>
